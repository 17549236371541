<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <div>
        <FuNav
          :fixed="false"
          :title="$t('claimsDetails')"
          :arrow="true"
          percent
        />
        <Basic />

        <div v-if="!loading">
          <div v-for="(item, index) in formAndData" :key="`FuFormDetails${index}`">
            <p class="form-title">{{ item.formProcessName }}</p>
            <FuFormDetails
              :columns="item.formatFormSchema"
              :datas="item.formatFormData"
            />
          </div>
        </div>

        <div class="contact-box">
          <img :src="portal32" alt="">
          <div>
            <p class="contact-title">{{ $t('ebClaim.shareTitle') }}</p>
            <p>{{ $t('ebClaim.shareDesc') }}</p>
          </div>
          <img
            :src="portal31"
            alt=""
            @click="sendWhatsapp"
          >
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FuNav from '@/components/v2/FuNav'
import FuFormDetails from '@/components/FuFormDetails/index.vue'
import Basic from './components/basic/index.vue'
import portal31 from '@/assets/imgs/portal31.png'
import portal32 from '@/assets/imgs/portal32.png'
import { queryClaimDetailsInfo } from '@/views/claim/api/index.js'
import('@/views/claim/eb/css/reset.css')
export default {
  components: { FuFormDetails, Basic, FuNav },
  props: {},
  data() {
    return {
      portal31, portal32,
      loading: false,
      formAndData: []
    }
  },
  activated() {
    this.init()
  },
  methods: {
    init() {
      this.formAndData = []
      this.queryClaimDetailsInfo()
    },
    async queryClaimDetailsInfo() {
      const { registerUid } = this.$route.query
      this.loading = true
      const res = await queryClaimDetailsInfo({ registerUid })
      this.formAndData = res.filter(item => {
        return item.formData
      }).map(item => {
        item.formatFormSchema = JSON.parse(JSON.parse(item.formSchema).displaySchema)
        item.formatFormData = item.formData ? JSON.parse(item.formData) : {}
        return item
      })
      // console.log(this.formAndData)
      this.loading = false
    },
    sendWhatsapp() {
      location.href = `whatsapp://share.to?mobile=62${this.phone}&text=`
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
  overflow: scroll;
}
.form-title {
  font-size: 16px;
  color: #EE0A24;
  padding: 10px 15px;
}
.contact-box{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  background-color: #f5f5f5;
  padding: 15px 15px 10px 2px;
  border-radius: 10px;
  .contact-title {
    font-size: 14px;
    font-family: 'Montserrat-Bold';
  }
}
</style>
